import React, { useState } from "react";
import { Link } from "react-router-dom";

const CategoryItem = ({ category }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Render the category with a toggle button */}
      <p className="list_item">
        <span
          onClick={toggleCollapse}
          className="toggle_button"
          style={{
            visibility: category.subcategories.length == 0 ? "hidden" : "",
          }}
        >
          {isOpen ? "▼" : "▶"} {/* Icon changes based on open/closed state */}
        </span>

        <Link to={`${category.id}`} className="list_item_link">
          {category.name} ({category.product_count})
        </Link>
      </p>

      {/* Render subcategories if the category is open */}
      {isOpen && category.subcategories.length > 0 && (
        <div className="sub_list_group">
          {category.subcategories.map((subcategory) => (
            <CategoryItem key={subcategory.id} category={subcategory} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryItem;
