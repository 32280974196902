import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import "./styles.css";
import CategoryItem from "../CategoryItem";

const FilterComponent = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  const fetchCategories = async () => {
    try {
      setCategories([]);
      let url = `https://server.reliablepte.com/api/categories`;

      let response = await axios.get(url);

      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBrands = async () => {
    try {
      setBrands([]);
      let url = `https://server.reliablepte.com/api/brands`;

      let response = await axios.get(url);

      setBrands(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, []);

  return (
    <div className="filter-container container">
      <div className="category-menu">
        <h5></h5>
        <br />
        <br />

        <div className="list-group">
          {categories.map((category) => (
            <CategoryItem key={category.id} category={category} />
          ))}
        </div>
      </div>
      <br />
      <br />
      <div className="brand-menu">
        <h5></h5>
      </div>
    </div>
  );
};

export default FilterComponent;
